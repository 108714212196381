// 別ウィンドウ表記
global.openNewWindow = function( url, name, options ) {
  window.open( url, name, options );
};

$( document ).on( 'turbolinks:load', function(){
  // wysiwyg的なボタンの設置
  $( 'textarea.add_button' ).each( function(){
    $( this ).before( buttonList );
  } );
} );

/***** wysiwyg的なボタンの実装 *****/
// テキストエリアの直前に表示するボタンの種類
const buttonList = '<div class="tag_wrap">' +
                     '<span class="tag" onclick="addWord( this, \'異常\' );">異常</span>' +
                     '<span class="tag" onclick="addWord( this, \'今回\' );">今回</span>' +
                     '<span class="tag" onclick="addWord( this, \'運転状況\' );">運転状況</span>' +
                     '<span class="tag" onclick="addWord( this, \'問題\' );">問題</span>' +
                     '<span class="tag" onclick="addWord( this, \'異音\' );">異音</span>' +
                     '<span class="tag" onclick="addWord( this, \'順調\' );">順調</span>' +
                     '<span class="tag" onclick="addWord( this, \'負荷\' );">負荷</span>' +
                     '<span class="tag" onclick="addWord( this, \'高い\' );">高い</span>' +
                     '<span class="tag" onclick="addWord( this, \'低い\' );">低い</span>' +
                     '<span class="tag" onclick="addWord( this, \'遮断\' );">遮断</span>' +
                     '<span class="tag" onclick="addWord( this, \'器\' );">器</span>' +
                     '<span class="tag" onclick="addWord( this, \'電気\' );">電気</span>' +
                     '<span class="tag" onclick="addWord( this, \'電力\' );">電力</span>' +
                     '<span class="tag" onclick="addWord( this, \'電線\' );">電線</span>' +
                     '<span class="tag" onclick="addWord( this, \'キュービクル\' );">キュービクル</span>' +
                     '<span class="tag" onclick="addWord( this, \'担当\' );">担当</span>' +
                     '<span class="tag" onclick="addWord( this, \'、\' );">、</span>' +
                     '<span class="tag" onclick="addWord( this, \'。\' );">。</span>' +
                     '<span class="tag" onclick="addWord( this, \'「\' );">「</span>' +
                     '<span class="tag" onclick="addWord( this, \'」\' );">」</span>' +
                     '<span class="tag" onclick="addWord( this, \'（\' );">（</span>' +
                     '<span class="tag" onclick="addWord( this, \'）\' );">）</span>' +
                     '<span class="tag" onclick="addWord( this, \'※\' );">※</span>' +
                     '<span class="tag" onclick="addWord( this, \'は\' );">は</span>' +
                     '<span class="tag" onclick="addWord( this, \'の\' );">の</span>' +
                     '<span class="tag" onclick="addWord( this, \'が\' );">が</span>' +
                     '<span class="tag" onclick="addWord( this, \'も\' );">も</span>' +
                     '<span class="tag" onclick="addWord( this, \'に\' );">に</span>' +
                     '<span class="tag" onclick="addWord( this, \'危険\' );">危険</span>' +
                     '<span class="tag" onclick="addWord( this, \'正常\' );">正常</span>' +
                     '<span class="tag" onclick="addWord( this, \'なく\' );">なく</span>' +
                     '<span class="tag" onclick="addWord( this, \'稼働\' );">稼働</span>' +
                     '<span class="tag" onclick="addWord( this, \'でした\' );">でした</span>' +
                     '<span class="tag" onclick="addWord( this, \'です\' );">です</span>' +
                     '<span class="tag" onclick="addWord( this, \'ます\' );">ます</span>' +
                     '<span class="tag" onclick="addWord( this, \'特に\' );">特に</span>' +
                   '</div>'

// タグ挿入ボタンが押された時の処理
global.addWord = function( obj, tag_name ) {
  // テキストエリアと選択されている場所を設定
  var target    = $( obj ).closest( 'div' ).next( 'textarea' );
  var positions = getAreaRange( target[0] );

  // 入力されている情報を選択されている部分とその前後に分割
  var value = target.val();
  var range = value.slice( positions.start, positions.end );
  var beforeNode = value.slice( 0, positions.start );
  var afterNode  = value.slice( positions.end );
  // タグで挟んだ文字列を入れ込む変数を設定しておく
  var insertNode = "";

  // 選択された部分の後ろに指定の文字を入れる
  insertNode = range + tag_name;

  // 分割した文字列を連結して表示
  target.val( beforeNode + insertNode + afterNode );
};

// 選択されている文字列を切り出す
var isIE = ( navigator.appName.toLowerCase().indexOf( 'internetexplorer' ) + 1 ? 1 : 0 );  // IEかどうかの判別
global.getAreaRange = function( obj ) {
  var positions = new Object();

  if( isIE ) {  // IEならこっち。既存の領域とは別の領域選択をして始点と終点を操作して文字数カウントにより位置を算出
    obj.focus();
    var range = document.selection.createRange();
    // 既存の選択範囲とは別に地点管理用にコピー
    var clone = range.duplicate();

    clone.moveToElementText( obj );
    clonse.setEndPoint( 'EndToEnd', range );

    positions.start = clone.text.length - range.text.length;
    positions.end   = clone.text.length - range.text.length + range.text.length;
    range.text.length;
  } else if( window.getSelection() ) {  // getSelectionが使える場合はこっち。シンプル。
    positions.start = obj.selectionStart;
    positions.end   = obj.selectionEnd;
  }
  return positions;
};

/***** Canvasによる手書き署名の実装 *****/
// 変数設定
var canvas;
var ct;
var ox = 0,oy = 0,x = 0,y = 0;
var mf = false;
var Xadjust, Yadjust, defSize, myStorage;
global.setSignatureCanvas = function(){
  //初期設定
  canvas = document.getElementById( "signature_canvas" );
  canvas.addEventListener( "touchstart", onDown,      false);
  canvas.addEventListener( "touchmove",  onMove,      false);
  canvas.addEventListener( "touchend",   onUp,        false);
  canvas.addEventListener( "mousedown",  onMouseDown, false);
  canvas.addEventListener( "mousemove",  onMouseMove, false);
  canvas.addEventListener( "mouseup",    onMouseUp,   false);

  ct = canvas.getContext("2d");
  ct.strokeStyle = "#000";
  ct.lineWidth   = 2;
  ct.lineJoin    = "round";
  ct.lineCap     = "round";

  //スクロール禁止
  document.addEventListener( 'touchmove', handleTouchMove, { passive: false } );

  // 表示位置の調整
  Xadjust = ( $( window ).width() - $( '#signature_box' ).width() ) / 2;
  Yadjust = ( $( window ).height() - $( '#signature_box' ).height() ) / 2;
  $( '#signature_box' ).css( { top: Yadjust + "px", left: Xadjust + "px" } );

  // ストレージの初期化
  myStorage = localStorage;
  window.onload = initLocalStorage();

  clearCanvas();
}

global.onDown = function( event ){
  mf = true;
  //ox = event.touches[0].pageX - event.target.getBoundingClientRect().left;
  //oy = event.touches[0].pageY - event.target.getBoundingClientRect().top;
  ox = event.touches[0].pageX - window.pageXOffset - Xadjust - 50;
  oy = event.touches[0].pageY - window.pageYOffset - Yadjust - 20;
  event.stopPropagation();
}

global.onMove = function( event ){
  if(mf){
    //x = event.touches[0].pageX - event.target.getBoundingClientRect().left;
    //y = event.touches[0].pageY - event.target.getBoundingClientRect().top;
    x = event.touches[0].pageX - window.pageXOffset - Xadjust - 50;
    y = event.touches[0].pageY - window.pageYOffset - Yadjust - 20;
    drawLine();
    ox = x;
    oy = y;
    event.preventDefault();
    event.stopPropagation();
  }
}

global.onUp = function( event ){
  mf = false;
  event.stopPropagation();
}

global.onMouseDown = function( event ){
  ox = event.clientX - event.target.getBoundingClientRect().left;
  oy = event.clientY - event.target.getBoundingClientRect().top ;
  mf = true;
}

global.onMouseMove = function( event ){
  if(mf){
    x = event.clientX - event.target.getBoundingClientRect().left;
    y = event.clientY - event.target.getBoundingClientRect().top ;
    drawLine();
    ox = x;
    oy = y;
  }
}

global.onMouseUp = function( event ){
  mf = false;
}

global.drawLine = function(){
  ct.beginPath();
  ct.moveTo( ox, oy );
  ct.lineTo( x, y );
  ct.stroke();
}

global.clearCanvas = function(){
  initLocalStorage();
  temp = [];
  resetCanvas();
};

global.resetCanvas = function() {
  //ctx.clearRect( 0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight );
  ct.clearRect( 0, 0, ct.canvas.clientWidth, ct.canvas.clientHeight );
};

global.chgImg = function( url ){
  removeTouchmove();
  var png = canvas.toDataURL( "image/png" );
  $.post( url, { signature: png.replace( /^.*,/, '' ) } );
};

global.initLocalStorage = function(){
  myStorage.setItem( "__log", JSON.stringify( [] ) );
};

global.setLocalStoreage = function(){
  var png = canvas.toDataURL();
  var logs = JSON.parse( myStorage.getItem( "__log" ) );

  setTimeout( function(){
    logs.unshift( { 0:png } );

    myStorage.setItem( "__log", JSON.stringify( logs ) );

    currentCanvas = 0;
    temp = [];
  }, 0 );
};

global.handleTouchMove = function( event ) {
  event.preventDefault();
};

global.removeTouchmove = function() {
  //スクロール復帰
  document.removeEventListener( 'touchmove', handleTouchMove, { passive: false } );
};

global.closeSignatureForm = function() {
  removeTouchmove();
  $( '#signature_form' ).remove();
};

